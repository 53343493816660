import { CircularProgress, Stack, useTheme } from '@mui/material';

import Paragraph from '~/components/atoms/Paragraph';
import FrontContainer from '~/components/molecules/FrontContainer';
import BlankLayout from '~/components/templates/Blank';

import LoadingPageProps from './types';

const LoadingPage = ({ label }: LoadingPageProps) => {
    const theme = useTheme();

    return (
        <BlankLayout showLogo sx={{ backgroundColor: theme.palette.background.default }}>
            <FrontContainer maxWidth="md">
                <Stack
                    sx={{
                        height: '70vh',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: 1,
                    }}
                >
                    <CircularProgress size={28} sx={{ mb: 6, color: theme.palette.text.primary }} />
                    <Paragraph>{label}</Paragraph>
                </Stack>
            </FrontContainer>
        </BlankLayout>
    );
};

export default LoadingPage;
