import { ReadableProvider } from '@allvit-labs/react-client';
import https from 'https';
import { FC, ReactNode, useContext, useEffect, useMemo, useRef, useState } from 'react';
import * as ReactRedux from 'react-redux';

// Remix
import {
    ActionFunction,
    ActionFunctionArgs,
    LinksFunction,
    LoaderFunction,
    LoaderFunctionArgs,
    json,
    redirect,
} from '@remix-run/node';
import {
    Links,
    Meta,
    Outlet,
    Scripts,
    ScrollRestoration,
    ShouldRevalidateFunction,
    isRouteErrorResponse,
    useLoaderData,
    useRouteError,
} from '@remix-run/react';

// @ts-ignore
import { ClientOnly } from 'remix-utils/client-only';
// @ts-ignore
import { ExternalScripts, ExternalScriptsHandle, ScriptDescriptor } from 'remix-utils/external-scripts';

// MUI
import { withEmotionCache } from '@emotion/react';
import { CssBaseline } from '@mui/material';
import useEnhancedEffect from '@mui/utils/useEnhancedEffect';

// Contexts
import CustomThemeProvider from '~/theme/CustomThemeProvider';

// Types
import { UserOnboardActionTypesEnum } from '~/types/app/user';

// Config
import ErrorPage from '~/components/pages/Error';
import globalConfig from '~/configs/global';
import { store } from '~/store';
import DataCollectionConsent from './components/organisms/DataCollectionConsent';
import LoadingPage from './components/pages/Loading';
import { ColorModeContext } from './contexts/ColorMode';
import {
    consentCookie,
    createRedirectCookie,
    createSignoutCookie,
    deleteRedirectCookie,
    deleteSignoutCookie,
} from './cookies.server';
import { ConsentObject } from './routes/enable-analytics';
import { ClaimRewardActionType } from './routes/profil.verve';
import { FrontendConfigType } from './types/util-types';
import ClientStylesContext, { ClientStyleContextData } from './utils/ClientStyleContext';
import { claimMagicLinkReward } from './utils/apis/magicLink';
import { getFunctionalCookieValue, setFunctionalCookieValue } from './utils/cookies';
import { featureTest } from './utils/environment';
import { buildErrorMessage } from './utils/error';
import { useAnalytics } from './utils/hooks/useAnalytics';
import { flushCacheAndRepplyStyles } from './utils/mui';
import { isConsentCookieIncomplete } from './utils/track';
import { updateHubspotContact } from './utils/track.server';
import { fetchUser } from './utils/user';

export type RootLoaderData = {
    currentUser: Awaited<ReturnType<typeof fetchUser>>;
    ENV: FrontendConfigType;
    consentObject: ConsentObject | null;
};

export function meta(): Array<{ [key: string]: string | undefined }> {
    const metaTags = [
        { charset: 'utf-8' },
        { title: 'Allvit' },
        {
            name: 'viewport',
            content: 'width=device-width,initial-scale=1',
        },
        { name: 'robots', content: `${featureTest(['prod']) ? 'index' : 'noindex'}` },
        { name: 'msapplication-tilecolor', content: '#faf8f6' },
        { name: 'msapplication-tileimage', content: '/logo/ms-icon-144x144.png' },
        { name: 'theme-color', content: '#faf8f6' },
        { name: 'emotion-insertion-point', content: 'emotion-insertion-point' },
    ];

    if (globalConfig.featureFlags.isAnalyticsEnvironment) {
        metaTags.push({
            name: 'facebook-domain-verification',
            content: globalConfig.publicKeys.FACEBOOK_PAGE_VERIFICATION,
        });
        metaTags.push({ name: 'google-site-verification', content: globalConfig.publicKeys.GOOGLE_SITE_VERIFICATION });
    }

    return metaTags;
}

export const links: LinksFunction = () => {
    return [
        {
            rel: 'icon',
            type: 'image/png',
            href: '/favicon/favicon-48x48.png',
            sizes: '48x48',
            media: '(prefers-color-scheme:light)',
        },
        {
            rel: 'icon',
            type: 'image/svg+xml',
            href: '/favicon/favicon.svg',
            media: '(prefers-color-scheme:light)',
        },
        {
            rel: 'shortcut icon',
            href: '/favicon/favicon.ico',
            media: '(prefers-color-scheme:light)',
        },
        {
            rel: 'apple-touch-icon',
            sizes: '180x180',
            href: '/favicon/apple-touch-icon.png',
            type: 'image/png',
        },

        {
            rel: 'icon',
            sizes: '192x192',
            href: '/favicon/android-icon-192x192.png',
            type: 'image/png',
        },
        {
            rel: 'icon',
            sizes: '96x96',
            href: '/favicon/favicon-white-32x32.png',
            type: 'image/png',
            media: '(prefers-color-scheme:dark)',
        },
    ];
};

const scripts = ({ data }: { data: any }): Array<ScriptDescriptor & { id: string }> => {
    const scripts: Array<ScriptDescriptor & { id: string }> = [
        {
            type: 'text/javascript',
            src: `https://checkout.vipps.no/vippsCheckoutSDK.js`,
            id: 'vipps-script-loader',
            async: true,
        },
    ];
    if (globalConfig.featureFlags.isAnalyticsEnvironment) {
        if (data?.ENV?.HUBSPOT_PORTAL_ID) {
            scripts.push({
                type: 'text/javascript',
                src: `//js-eu1.hs-scripts.com/${data.ENV.HUBSPOT_PORTAL_ID}.js`,
                id: 'hs-script-loader',
                async: true,
                defer: true,
            });
        }
    }

    return scripts;
};

export const handle: ExternalScriptsHandle = { scripts };

export const shouldRevalidate: ShouldRevalidateFunction = ({ currentUrl, defaultShouldRevalidate }) => {
    if (currentUrl.href.includes('kode') || currentUrl.href.includes('velkommen')) return true;
    return defaultShouldRevalidate;
};

export const loader: LoaderFunction = async ({ request }: LoaderFunctionArgs) => {
    // Necessary locally but nowhere else
    https.globalAgent.options.rejectUnauthorized = !featureTest(['local']);

    const cookieHeader = request.headers.get('Cookie');
    const consent: { consentObject: ConsentObject } = (await consentCookie.parse(cookieHeader)) || {};
    const consentCookieIncomplete = isConsentCookieIncomplete(consent.consentObject);
    const redirectCookie: { redirect: string } = (await createRedirectCookie.parse(cookieHeader)) || {};
    const signoutCookie: { from: string } = (await createSignoutCookie.parse(cookieHeader)) || {};

    if (redirectCookie.redirect) {
        return redirect(redirectCookie.redirect, {
            headers: {
                'Set-Cookie': await deleteRedirectCookie.serialize({
                    redirect: '/',
                }),
            },
        });
    }

    const url = new URL(request.url);
    const urlFrags = url.pathname.split('/');

    const currentUser = await fetchUser(request);

    if (!currentUser.signedIn && signoutCookie.from) {
        return redirect(`/logg-inn?from=${signoutCookie.from}`, {
            headers: {
                'Set-Cookie': await deleteSignoutCookie.serialize(''),
            },
        });
    }
    if (globalConfig.featureFlags.isAnalyticsEnvironment && currentUser.signedIn && currentUser.user.loginEmail) {
        let properties: Record<string, string> = {};

        if (typeof currentUser.userHasAPlusSubscription !== 'undefined') {
            properties = {
                user_has_active_subscription: currentUser.userHasAPlusSubscription ? 'true' : 'false',
            };
        }

        if (typeof currentUser.subscriptionExpiresWithinTwoWeeks !== 'undefined') {
            properties = {
                ...properties,
                user_has_subscription_which_expires_within_two_weeks: currentUser.subscriptionExpiresWithinTwoWeeks
                    ? 'true'
                    : 'false',
            };
        }

        if (typeof currentUser.user?.phone !== 'undefined') {
            properties = {
                ...properties,
                mobilephone: currentUser.user.phone,
            };
        }

        if (typeof currentUser.additionalUserData?.yearOfGraduation !== 'undefined') {
            properties = {
                ...properties,
                graduation_year_new: String(currentUser.additionalUserData.yearOfGraduation),
            };
        }

        updateHubspotContact(currentUser.user.loginEmail, properties);
    }

    if (currentUser.error) {
        return redirect('/logg-ut?to=/autentiseringsfeil');
    }

    if (globalConfig.maintenance && urlFrags[1] !== 'vedlikehold') {
        return redirect('/vedlikehold');
    }

    // Testing onboarding
    // currentUser.user.onboardActions = [
    //     UserOnboardActionTypesEnum.onboardUserType,
    //     UserOnboardActionTypesEnum.onboardTopics,
    //     UserOnboardActionTypesEnum.onboardInstituteInfo,
    //     UserOnboardActionTypesEnum.onboardInfo,
    //     UserOnboardActionTypesEnum.onboardEmailVerification,
    // ];

    // Signed in, not onboarded
    if (
        !urlFrags.includes('kode') &&
        !urlFrags.includes('velkommen') &&
        !urlFrags.includes('personvern') &&
        !urlFrags.includes('confirm_email') &&
        currentUser.signedIn &&
        currentUser.user.onboardActions.filter((el) => Object.values(UserOnboardActionTypesEnum).includes(el)).length >
            0
    ) {
        if (url.pathname && url.search) {
            return redirect(`/velkommen?from=${decodeURIComponent(url.pathname + url.search)}`);
        } else {
            return redirect('/velkommen');
        }
    }

    // Entering onboarding, but is already onboarded - signed in
    if (urlFrags[1] === 'velkommen' && currentUser.signedIn && currentUser.user.onboardActions.length < 1) {
        return redirect('/');
    }

    // Entering onboarding - not signed in
    if (urlFrags[1] === 'velkommen' && !currentUser.signedIn) {
        return redirect('/');
    }

    // Accessing public area, signed in
    if (globalConfig.onlyPublicAreas.includes(`/${urlFrags[1]}`) && currentUser.signedIn) {
        return redirect('/');
    }

    // Accessing private area, not signed in
    if (globalConfig.privateAreas.includes(`/${urlFrags[1]}`) && !currentUser.signedIn) {
        return redirect(`/logg-ut?from=${decodeURIComponent(url.pathname)}&reason=restricted.area`);
    }

    return json<RootLoaderData>({
        currentUser,
        ENV: {
            ENDPOINT_HOST: `${process.env.ENDPOINT_HOST || ''}`,
            NODE_ENV: `${process.env.NODE_ENV || 'undefined'}`,
            HUBSPOT_PORTAL_ID: globalConfig.publicKeys.HUBSPOT_PORTAL_ID,
            CRYSTALLIZE_TENANT_IDENTIFIER: `${process.env.CRYSTALLIZE_TENANT_IDENTIFIER || ''}`,
            ALLVIT_FRONTEND_ENV: `${process.env.ALLVIT_FRONTEND_ENV}`,
        },
        consentObject: consentCookieIncomplete ? null : consent.consentObject,
    });
};

export const action: ActionFunction = async ({ request }: ActionFunctionArgs) => {
    const formData = await request.formData();
    const action = formData.get('action') as string;
    if (action === 'claimMagicLinkReward') {
        const result = await claimMagicLinkReward(request);

        if (!result.success) {
            throw new Response(buildErrorMessage(result, 'Claiming magic link reward failed'), {
                status: result.status,
            });
        } else {
            return json<ClaimRewardActionType>({ success: true });
        }
    }
};

const Document: FC<{ children: ReactNode }> = withEmotionCache(({ children }, emotionCache) => {
    const { ENV, currentUser } = useLoaderData() as RootLoaderData;

    const userForWindow = currentUser.signedIn
        ? {
              signedIn: currentUser.signedIn,
              id: currentUser.user.id,
              email: currentUser.user.loginEmail,
          }
        : {};

    const clientStyleData: ClientStyleContextData = useContext(ClientStylesContext);

    const htmlRef = useRef<HTMLHtmlElement>(null);

    useEffect(() => {
        if (htmlRef.current && htmlRef.current.classList) {
            htmlRef.current.classList.add('hydrated');
        }
    }, []);

    if (typeof window !== 'undefined') {
        useEnhancedEffect(() => {
            flushCacheAndRepplyStyles(emotionCache, clientStyleData);
        }, []);
    }

    return (
        <html ref={htmlRef} lang="nb">
            <head>
                <Meta />
                <Links />
                <script
                    suppressHydrationWarning
                    dangerouslySetInnerHTML={{
                        __html: `window.ENV = ${JSON.stringify(ENV)};window.USER = ${JSON.stringify(userForWindow)}`,
                    }}
                />
            </head>
            <body>
                <div className="globalContainer">{children}</div>
                <ScrollRestoration />
                <Scripts crossOrigin="anonymous" />
                <ClientOnly fallback={<></>}>{() => <ExternalScripts />}</ClientOnly>
            </body>
        </html>
    );
});

export const ErrorBoundary = withEmotionCache((_undefined, emotionCache) => {
    const clientStyleData: ClientStyleContextData = useContext(ClientStylesContext);
    const error: any = useRouteError();

    useEffect(() => {
        // Only executed on client

        if (error.status === 403) {
            setTimeout(() => {
                window.location.replace(`/logg-ut?to=/logg-inn&reason=${error.data}`);
            }, 500);
        }
    }, []);

    if (typeof window !== 'undefined') {
        useEnhancedEffect(() => {
            flushCacheAndRepplyStyles(emotionCache, clientStyleData);
        }, []);
    }

    let errorContent = <></>;
    let errorMessage = '';
    if (isRouteErrorResponse(error)) {
        if (error.status === 403) {
            errorContent = <LoadingPage label="Logger ut" />;
        } else if (error.status === 404) {
            errorContent = <ErrorPage status={404} />;
        } else {
            errorContent = (
                <ErrorPage status={error.status}>
                    {error.status && <>{error.status}</>}
                    {error.statusText && (
                        <>
                            <br />
                            {error.statusText}
                        </>
                    )}
                    {error.data && (
                        <>
                            <br />
                            {error.data}
                        </>
                    )}
                    <br />
                    {!error.statusText && !error.data && <>Somehow, your path took a wrong turn</>}
                </ErrorPage>
            );
        }
    } else {
        if (error instanceof Error) {
            errorMessage = error.message;
        }
        errorContent = <ErrorPage status="failure">{errorMessage}</ErrorPage>;
    }

    return (
        <html>
            <head>
                <title>{errorMessage !== '' ? `Oh no! : ${errorMessage}` : 'Error'}</title>
                <Meta />
                <Links />
            </head>
            <body>
                <CustomThemeProvider colorMode="light">
                    <CssBaseline />
                    {errorContent}
                </CustomThemeProvider>

                <Scripts />
            </body>
        </html>
    );
});

const App: FC = () => {
    const { currentUser, consentObject } = useLoaderData<typeof loader>() as RootLoaderData;
    const { enableTracking, disableTracking } = useAnalytics();

    useEffect(() => {
        if (!currentUser?.user?.organisation?.isOrganisationUser) {
            if (currentUser.signedIn) {
                // Signed in users always have tracking enabled
                enableTracking({ setCookie: true });
            } else if (!consentObject) {
                // User has not interacted with the consent banner.
                // We default to tracking enabled and reconfigure after they specifiy their consent.
                enableTracking({ setCookie: false });
            } else if (consentObject && !consentObject?.consentToAnalytics && !currentUser.signedIn) {
                // Users that already have a consent cookie set to onlyNecessary
                disableTracking({ setCookie: false });
            }
        }
    }, []);

    useEffect(() => {
        if (currentUser.signedIn) {
            const darkMode = getFunctionalCookieValue('darkMode');

            if (darkMode && darkMode.value === 'dark') {
                setMode('dark');
            }
        }
    }, [currentUser.signedIn]);

    const [mode, setMode] = useState<'light' | 'dark'>('light');

    const colorMode = useMemo(
        () => ({
            toggleColorMode: () => {
                setMode((prevMode) => {
                    setFunctionalCookieValue('darkMode', {
                        value: prevMode === 'light' ? 'dark' : 'light',
                        expires: Date.now() + globalConfig.darkModeCookieExpiryTime,
                    });
                    return prevMode === 'light' ? 'dark' : 'light';
                });
            },
        }),
        [],
    );

    return (
        <Document>
            <ReactRedux.Provider store={store}>
                <ReadableProvider>
                    <ColorModeContext.Provider value={colorMode}>
                        <CustomThemeProvider colorMode={!currentUser.signedIn ? 'light' : mode}>
                            <CssBaseline />
                            <Outlet />
                            {!currentUser.signedIn && !consentObject && <DataCollectionConsent />}
                        </CustomThemeProvider>
                    </ColorModeContext.Provider>
                </ReadableProvider>
            </ReactRedux.Provider>
        </Document>
    );
};

export default App;
